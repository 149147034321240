var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%" }, attrs: { id: "home_container" } },
    [
      _c("div", { attrs: { id: "section1" } }, [
        _c(
          "div",
          { attrs: { id: "content" } },
          [
            _c(
              "el-form",
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: { "align-self": "flex-end" },
                        attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
                      },
                      [
                        _c("el-autocomplete", {
                          staticStyle: { width: "850px" },
                          attrs: {
                            placeholder: _vm.$t("queryParam"),
                            "prefix-icon": "el-icon-help",
                            "fetch-suggestions": _vm.querySearchCodeAsync
                          },
                          on: {
                            select: _vm.handleSelect,
                            change: _vm.handleChange
                          },
                          model: {
                            value: _vm.SeaCodeVal,
                            callback: function($$v) {
                              _vm.SeaCodeVal = $$v
                            },
                            expression: "SeaCodeVal"
                          }
                        }),
                        _c(
                          "el-button",
                          {
                            attrs: { title: _vm.$t("search"), type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.SelectData()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("search")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  {
                    staticStyle: {
                      "border-top": "1px solid #f5f7fa",
                      "border-bottom": "1px solid #f5f7fa",
                      "margin-top": "10px"
                    }
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 12, md: 8, lg: 9, xl: 9 } },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-weight": "700",
                              "font-size": "16px",
                              color: "#fff"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.isBrandBusiness
                                  ? _vm.$t("MASTER PO NUMBER")
                                  : _vm.$t("PO")
                              )
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 12, md: 8, lg: 8, xl: 8 } },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-weight": "700",
                              "font-size": "14px",
                              color: "#fff"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.isBrandBusiness
                                  ? _vm.$t("MASTER STYLE NUMBER")
                                  : _vm.$t("FINISHED ITEM NUMBER")
                              )
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-weight": "700",
                              "font-size": "14px",
                              color: "#fff"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("BUSINESS SERIAL NUMBER INTERNAL"))
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 2, md: 2, lg: 1, xl: 1 } },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-weight": "700",
                              "font-size": "14px",
                              color: "#fff"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("OPERATION")))]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._l(_vm.poListTable, function(item, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 12, md: 8, lg: 9, xl: 9 } },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "dark",
                                    content: item.po,
                                    placement: "top-start"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-weight": "600",
                                        height: "24px",
                                        "text-overflow": "ellipsis",
                                        overflow: "hidden",
                                        "white-space": "nowrap",
                                        width: "250px",
                                        color: "#fff"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.po))]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 12, md: 8, lg: 8, xl: 8 } },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "dark",
                                    content: item.code,
                                    placement: "top-start"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        height: "24px",
                                        "text-overflow": "ellipsis",
                                        overflow: "hidden",
                                        "white-space": "nowrap",
                                        width: "210px",
                                        color: "#fff"
                                      }
                                    },
                                    [_vm._v(_vm._s(item.code))]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 12, md: 6, lg: 6, xl: 6 } },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: { color: "#fff", width: "100px" }
                                },
                                [_vm._v(_vm._s(item.businessNoIn) + " ")]
                              )
                            ]
                          ),
                          _c(
                            "el-col",
                            { attrs: { xs: 24, sm: 2, md: 2, lg: 1, xl: 1 } },
                            [
                              _c("el-button", {
                                staticStyle: {
                                  "padding-left": "10px",
                                  "padding-top": "2px"
                                },
                                attrs: {
                                  icon: "icon-dingwei1",
                                  type: "text",
                                  size: "medium",
                                  title: _vm.$t("Look Over")
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.submitSelectData(item)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                _c(
                  "div",
                  { staticClass: "pvhFactory" },
                  [
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          title: _vm.$t("RELATED PO"),
                          visible: _vm.dialogTableVisible,
                          "modal-append-to-body": false,
                          width: "90%",
                          top: "2vh"
                        },
                        on: {
                          "update:visible": function($event) {
                            _vm.dialogTableVisible = $event
                          }
                        }
                      },
                      [
                        _c(
                          "avue-crud",
                          {
                            ref: "crud",
                            attrs: {
                              option: _vm.tableOpt,
                              "row-style": _vm.rowStyle,
                              data: _vm.potable,
                              page: _vm.page
                            },
                            on: {
                              "update:page": function($event) {
                                _vm.page = $event
                              },
                              "current-change": _vm.currentChange
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "menu",
                                fn: function(ref) {
                                  var row = ref.row
                                  var index = ref.index
                                  var size = ref.size
                                  var type = ref.type
                                  return [
                                    _c("el-button", {
                                      attrs: {
                                        icon: "icon-dingwei1",
                                        type: "text",
                                        size: "medium",
                                        title: _vm.$t("Look Over")
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.submitSelectData(row)
                                        }
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _c(
                              "template",
                              {
                                staticStyle: { width: "calc(100% - 80px)" },
                                slot: "menuLeft"
                              },
                              [
                                !_vm.isAdvancedSearch
                                  ? _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticStyle: {
                                              "align-self": "flex-end"
                                            },
                                            attrs: {
                                              xs: 24,
                                              sm: 24,
                                              md: 24,
                                              lg: 24,
                                              xl: 24
                                            }
                                          },
                                          [
                                            _c("el-autocomplete", {
                                              staticStyle: { width: "550px" },
                                              attrs: {
                                                placeholder: _vm.$t(
                                                  "queryParam"
                                                ),
                                                "prefix-icon": "el-icon-help",
                                                "fetch-suggestions":
                                                  _vm.querySearchCodeAsync
                                              },
                                              on: {
                                                select: _vm.handleSelect,
                                                change: _vm.handleChange
                                              },
                                              model: {
                                                value: _vm.SeaCodeVal,
                                                callback: function($$v) {
                                                  _vm.SeaCodeVal = $$v
                                                },
                                                expression: "SeaCodeVal"
                                              }
                                            }),
                                            _c(
                                              "el-badge",
                                              {
                                                staticStyle: {
                                                  "margin-right": "18px"
                                                },
                                                attrs: {
                                                  value: _vm.selectBadge
                                                }
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      plain: "",
                                                      icon:
                                                        "iconfont shaixuan2",
                                                      size: "mini",
                                                      title: _vm.$t(
                                                        "Display Filter"
                                                      )
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.isAdvancedSearch = true
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("Filter"))
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  title: _vm.$t("Search"),
                                                  icon: "iconfont sousuo",
                                                  size: "mini",
                                                  type: "primary"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.SelectData()
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(_vm.$t("Search"))
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isAdvancedSearch
                                  ? _c(
                                      "el-row",
                                      {
                                        staticClass: "topFromSearch",
                                        attrs: { gutter: 20 }
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticStyle: { height: "auto" },
                                            attrs: {
                                              xs: 24,
                                              sm: 24,
                                              md: 24,
                                              lg: 24,
                                              xl: 24
                                            }
                                          },
                                          [
                                            _c(
                                              "el-col",
                                              {
                                                staticClass: "formDiv",
                                                attrs: {
                                                  xs: 24,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 4,
                                                  xl: 4
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    style:
                                                      _vm.SeaCodeVal == ""
                                                        ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                                        : "top: -10px;left: 20px;transform: translate(0, 0);"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("KEYWORDS")
                                                      ) + ":"
                                                    )
                                                  ]
                                                ),
                                                _c("el-input", {
                                                  on: {
                                                    focus: function($event) {
                                                      return _vm.focusDiv(
                                                        $event
                                                      )
                                                    },
                                                    blur: function($event) {
                                                      return _vm.blurDiv($event)
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.SeaCodeVal,
                                                    callback: function($$v) {
                                                      _vm.SeaCodeVal = $$v
                                                    },
                                                    expression: "SeaCodeVal"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                staticClass: "formDiv",
                                                attrs: {
                                                  xs: 24,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 4,
                                                  xl: 4
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    style:
                                                      _vm.poVal == ""
                                                        ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                                        : "top: -10px;left: 20px;transform: translate(0, 0);"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.isBrandBusiness
                                                          ? _vm.$t(
                                                              "MASTER PO NUMBER"
                                                            )
                                                          : _vm.$t("PO")
                                                      ) + ":"
                                                    )
                                                  ]
                                                ),
                                                _c("el-input", {
                                                  on: {
                                                    focus: function($event) {
                                                      return _vm.focusDiv(
                                                        $event
                                                      )
                                                    },
                                                    blur: function($event) {
                                                      return _vm.blurDiv($event)
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.poVal,
                                                    callback: function($$v) {
                                                      _vm.poVal = $$v
                                                    },
                                                    expression: "poVal"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                staticClass: "formDiv",
                                                attrs: {
                                                  xs: 24,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 4,
                                                  xl: 4
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    style:
                                                      _vm.codeVal == ""
                                                        ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                                        : "top: -10px;left: 20px;transform: translate(0, 0);"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.isBrandBusiness
                                                          ? _vm.$t(
                                                              "MASTER STYLE NUMBER"
                                                            )
                                                          : _vm.$t(
                                                              "FINISHED ITEM NUMBER"
                                                            )
                                                      ) + ":"
                                                    )
                                                  ]
                                                ),
                                                _c("el-input", {
                                                  on: {
                                                    focus: function($event) {
                                                      return _vm.focusDiv(
                                                        $event
                                                      )
                                                    },
                                                    blur: function($event) {
                                                      return _vm.blurDiv($event)
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.codeVal,
                                                    callback: function($$v) {
                                                      _vm.codeVal = $$v
                                                    },
                                                    expression: "codeVal"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                staticClass: "formDiv",
                                                attrs: {
                                                  xs: 24,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 4,
                                                  xl: 4
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    style:
                                                      _vm.colorVal == ""
                                                        ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                                        : "top: -10px;left: 20px;transform: translate(0, 0);"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("COLOR")) +
                                                        ":"
                                                    )
                                                  ]
                                                ),
                                                _c("el-input", {
                                                  on: {
                                                    focus: function($event) {
                                                      return _vm.focusDiv(
                                                        $event
                                                      )
                                                    },
                                                    blur: function($event) {
                                                      return _vm.blurDiv($event)
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.colorVal,
                                                    callback: function($$v) {
                                                      _vm.colorVal = $$v
                                                    },
                                                    expression: "colorVal"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                staticClass: "formDiv",
                                                attrs: {
                                                  xs: 24,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 4,
                                                  xl: 4
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    style:
                                                      _vm.vendorVal.ids
                                                        .length == 0
                                                        ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                                        : "top: -10px;left: 20px;transform: translate(0, 0);",
                                                    attrs: { id: "venSpan" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("VENDOR")) +
                                                        ":"
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%"
                                                    },
                                                    attrs: {
                                                      multiple: "",
                                                      "collapse-tags": "",
                                                      placeholder: "",
                                                      clearable: ""
                                                    },
                                                    on: {
                                                      focus: function($event) {
                                                        return _vm.focusDiv(
                                                          $event
                                                        )
                                                      },
                                                      blur: function($event) {
                                                        return _vm.blurDiv(
                                                          $event
                                                        )
                                                      },
                                                      change: _vm.venchang
                                                    },
                                                    model: {
                                                      value: _vm.vendorVal.ids,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.vendorVal,
                                                          "ids",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "vendorVal.ids"
                                                    }
                                                  },
                                                  _vm._l(
                                                    this.vendorData,
                                                    function(item) {
                                                      return _c("el-option", {
                                                        key: item.value,
                                                        attrs: {
                                                          label: item.label,
                                                          value: item.value
                                                        }
                                                      })
                                                    }
                                                  ),
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                staticClass: "formDiv",
                                                attrs: {
                                                  xs: 24,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 4,
                                                  xl: 4
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    style:
                                                      _vm.beginTimeVal == ""
                                                        ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                                        : "top: -10px;left: 20px;transform: translate(0, 0);"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("ORDERBEINGTIME")
                                                      ) + ":"
                                                    )
                                                  ]
                                                ),
                                                _c("el-date-picker", {
                                                  staticClass: "dataicon",
                                                  staticStyle: {
                                                    width: "100%"
                                                  },
                                                  attrs: {
                                                    type: "date",
                                                    format: "yyyy-MM-dd",
                                                    "value-format":
                                                      "yyyy-MM-dd",
                                                    "prefix-icon": ""
                                                  },
                                                  on: {
                                                    focus: function($event) {
                                                      return _vm.focusDiv(
                                                        $event
                                                      )
                                                    },
                                                    blur: function($event) {
                                                      return _vm.blurDiv($event)
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.beginTimeVal,
                                                    callback: function($$v) {
                                                      _vm.beginTimeVal = $$v
                                                    },
                                                    expression: "beginTimeVal"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                staticClass: "formDiv",
                                                attrs: {
                                                  xs: 24,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 4,
                                                  xl: 4
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    style:
                                                      _vm.endTimeVal == ""
                                                        ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                                        : "top: -10px;left: 20px;transform: translate(0, 0);"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("ORDERENDTIME")
                                                      ) + ":"
                                                    )
                                                  ]
                                                ),
                                                _c("el-date-picker", {
                                                  staticClass: "dataicon",
                                                  staticStyle: {
                                                    width: "100%"
                                                  },
                                                  attrs: {
                                                    type: "date",
                                                    format: "yyyy-MM-dd",
                                                    "value-format":
                                                      "yyyy-MM-dd",
                                                    "prefix-icon": ""
                                                  },
                                                  on: {
                                                    focus: function($event) {
                                                      return _vm.focusDiv(
                                                        $event
                                                      )
                                                    },
                                                    blur: function($event) {
                                                      return _vm.blurDiv($event)
                                                    }
                                                  },
                                                  model: {
                                                    value: _vm.endTimeVal,
                                                    callback: function($$v) {
                                                      _vm.endTimeVal = $$v
                                                    },
                                                    expression: "endTimeVal"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isAdvancedSearch
                                  ? _c(
                                      "el-row",
                                      {
                                        staticClass: "topFromSearch",
                                        attrs: { gutter: 20 }
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                              "align-self": "flex-end"
                                            },
                                            attrs: {
                                              xs: 24,
                                              sm: 24,
                                              md: 24,
                                              lg: 24,
                                              xl: 24
                                            }
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  plain: "",
                                                  icon: "iconfont shaixuan1",
                                                  size: "mini",
                                                  title: _vm.$t("Hidden Filter")
                                                },
                                                on: {
                                                  click: function($event) {
                                                    _vm.isAdvancedSearch = false
                                                    _vm.getSelectBadge()
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "  " +
                                                    _vm._s(
                                                      _vm.$t("Hidden Filter")
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  title: _vm.$t("Search"),
                                                  icon: "iconfont sousuo",
                                                  size: "mini",
                                                  type: "primary"
                                                },
                                                on: { click: _vm.searchFrom }
                                              },
                                              [
                                                _vm._v(
                                                  "  " +
                                                    _vm._s(_vm.$t("Search"))
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c("template", { slot: "tip" })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              2
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }