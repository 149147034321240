<template>
    <div id="home_container" style=" height: 100%;">
        <div id="section1">
            <div id="content">
                <el-form>
                    <el-row>
                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="align-self: flex-end; ">
                            <el-autocomplete v-model="SeaCodeVal" :placeholder="$t('queryParam')"
                                             prefix-icon="el-icon-help"
                                             style="width:850px;" :fetch-suggestions="querySearchCodeAsync" @select="handleSelect" @change="handleChange">
                            </el-autocomplete>
                            <el-button :title="$t('search')" type="primary" @click="SelectData()">{{$t('search')}}</el-button>
                        </el-col>
                    </el-row>
                    <el-row style="border-top: 1px solid #f5f7fa; border-bottom: 1px solid #f5f7fa; margin-top: 10px;">
                        <el-col :xs="24" :sm="12" :md="8" :lg="9" :xl="9">
                            <span style="font-weight: 700; font-size: 16px; color: #fff; ">{{isBrandBusiness? $t('MASTER PO NUMBER'):$t('PO')}}</span>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                            <span style="font-weight: 700; font-size: 14px; color: #fff; ">{{isBrandBusiness? $t('MASTER STYLE NUMBER'):$t('FINISHED ITEM NUMBER')}}</span>
                        </el-col>
                        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                            <span style="font-weight: 700; font-size: 14px; color: #fff;">{{$t('BUSINESS SERIAL NUMBER INTERNAL')}}</span>
                        </el-col>
                        <el-col :xs="24" :sm="2" :md="2" :lg="1" :xl="1">
                            <span style="font-weight: 700; font-size: 14px; color: #fff;">{{$t('OPERATION')}}</span>
                        </el-col>

                    </el-row>
                    <div v-for="(item,index) in poListTable" v-bind:key="index">
                        <el-row>
                            <el-col :xs="24" :sm="12" :md="8" :lg="9" :xl="9">
                                <el-tooltip effect="dark" :content="item.po" placement="top-start">
                                    <span style="font-weight: 600;height: 24px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; width: 250px; color: #fff">{{item.po }}</span>
                                </el-tooltip>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                                <el-tooltip effect="dark" :content="item.code" placement="top-start">
                                    <span style="height: 24px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: 210px; color: #fff">{{item.code }}</span>
                                </el-tooltip>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
                                <span style="color: #fff; width: 100px;">{{item.businessNoIn}} </span>
                            </el-col>

                            <el-col :xs="24" :sm="2" :md="2" :lg="1" :xl="1">
                                <el-button icon="icon-dingwei1" type="text" size="medium" :title="$t('Look Over')" @click="submitSelectData(item)" style="padding-left: 10px; padding-top: 2px;"></el-button>
                            </el-col>

                        </el-row>
                    </div>
                    <div class="pvhFactory">
                        <el-dialog :title="$t('RELATED PO')" :visible.sync="dialogTableVisible" :modal-append-to-body="false" width="90%" top="2vh">
                            <avue-crud ref="crud" :option="tableOpt" :row-style="rowStyle" :data="potable" :page.sync="page" @current-change="currentChange">
                                <template slot="menuLeft" style="width: calc(100% - 80px);">
                                    <el-row v-if="!isAdvancedSearch">
                                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="align-self: flex-end; ">
                                            <el-autocomplete v-model="SeaCodeVal" :placeholder="$t('queryParam')"
                                                             prefix-icon="el-icon-help"
                                                             style="width:550px;" :fetch-suggestions="querySearchCodeAsync" @select="handleSelect" @change="handleChange">
                                            </el-autocomplete>
                                            <el-badge :value="selectBadge" style="margin-right: 18px;">
                                                <el-button plain icon="iconfont shaixuan2" size="mini" :title="$t('Display Filter')" @click="isAdvancedSearch=true;">{{$t('Filter')}}</el-button>
                                            </el-badge>
                                            <el-button :title="$t('Search')" icon="iconfont sousuo" size="mini" type="primary" @click="SelectData()"> {{$t('Search')}}</el-button>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="20" class="topFromSearch" v-if="isAdvancedSearch">
                                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="height: auto;">
                                            <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                                <span :style="SeaCodeVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('KEYWORDS')}}:</span>
                                                <el-input @focus="focusDiv($event)" @blur="blurDiv($event)" v-model="SeaCodeVal"></el-input>
                                            </el-col>
                                            <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                                <span :style="poVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{isBrandBusiness? $t('MASTER PO NUMBER'):$t('PO')}}:</span>
                                                <el-input v-model="poVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                            </el-col>
                                            <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                                <span :style="codeVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{isBrandBusiness? $t('MASTER STYLE NUMBER'):$t('FINISHED ITEM NUMBER')}}:</span>
                                                <el-input v-model="codeVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                            </el-col>
                                            <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                                <span :style="colorVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('COLOR')}}:</span>
                                                <el-input v-model="colorVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                            </el-col>
                                            <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                                <span id="venSpan" :style="vendorVal.ids.length == 0?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('VENDOR')}}:</span>
                                                <el-select v-model="vendorVal.ids" @focus="focusDiv($event)" @blur="blurDiv($event)" multiple collapse-tags placeholder="" style="width:100%;" @change="venchang" clearable>
                                                    <el-option v-for="item in this.vendorData"
                                                               :key="item.value"
                                                               :label="item.label"
                                                               :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </el-col>
                                            <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                                <span :style="beginTimeVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('ORDERBEINGTIME')}}:</span>
                                                <el-date-picker class="dataicon" v-model="beginTimeVal"
                                                                type="date" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                                                format="yyyy-MM-dd" value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                                                </el-date-picker>
                                            </el-col>
                                            <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                                                <span :style="endTimeVal ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('ORDERENDTIME')}}:</span>
                                                <el-date-picker class="dataicon" v-model="endTimeVal"
                                                                type="date" @focus="focusDiv($event)" @blur="blurDiv($event)"
                                                                format="yyyy-MM-dd" value-format="yyyy-MM-dd" prefix-icon="" style="width:100%;">
                                                </el-date-picker>
                                            </el-col>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="20" class="topFromSearch" v-if="isAdvancedSearch">
                                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style=" text-align: right; align-self: flex-end;">
                                            <el-button plain icon="iconfont shaixuan1" size="mini" :title="$t('Hidden Filter')" @click="isAdvancedSearch=false;getSelectBadge()">  {{$t('Hidden Filter')}} </el-button>
                                            <el-button :title="$t('Search')" icon="iconfont sousuo" size="mini" type="primary" @click="searchFrom">  {{$t('Search')}}</el-button>
                                        </el-col>
                                    </el-row>
                                </template>
                                <template slot-scope="{row,index,size,type}" slot="menu">
                                    <el-button icon="icon-dingwei1" type="text" size="medium" :title="$t('Look Over')" @click="submitSelectData(row)"></el-button>
                                </template>
                                <!--<template slot="header">
                                    <el-button type="primary" @click="submitSelectData()">{{$t('Yes')}}</el-button>
                                </template>-->
                                <template slot="tip">
                                </template>
                            </avue-crud>
                        </el-dialog>

                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<style>
    .el-dialog__wrapper {
        transition-duration: 0.3s;
    }

    #section1 {
        width: 100%;
        height: 100%;
        background: url("/img/TracingSearchBG.jpg") center center no-repeat;
        background-size: cover;
        position: relative;
    }

    #content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    #section1::before {
        content: '';
        display: block;
        position: absolute;
        background-color: #000;
        opacity: 0.5;
        width: 100%;
        height: 100%;
    }

    .polist .avue-crud__menu {
        display: contents;
        -webkit-box-pack: justify;
        justify-content: space-between;
        position: relative;
        width: 100%;
        min-height: 0rem;
        height: auto;
        overflow: hidden;
        margin-bottom: 0rem;
        background-color: transparent !important; /* 背景透明 */
        color: #fff;
    }

    .polist .avue-crud .el-table th {
        word-break: break-word;
        color: #fff;
        background-color: transparent !important; /* 背景透明 */
    }

    .polist {
        background-color: transparent !important; /* 背景透明 */
    }

        .polist .avue-crud__pagination {
            position: relative;
            padding: 0.2rem 0 1rem 1rem;
            text-align: right;
        }
</style>
<script>
    import { PoAndDetailList } from "@/api/purchaseOrd";
    import { TracingDropDownList, TracingStringList, TracingUnifiedIsBool } from "@/api/unified";
    export default {
        data() {
            return {
                isBrandBusiness: false,
                isAdvancedSearch: false,
                selectBadge: null,
                codeVal: "", poVal: "", colorVal: "", beginTimeVal: "", endTimeVal: "", vendorVal: { ids: [], }, vendorData: [],
                no_SeaCodeVal: 'no', selectId: '', SeaCodeVal: '', dialogTableVisible: false, //当前查询值
                Coderestaurants: [], Codestate: '', Codetimeout: null,
                potable: [], codeslist: [], selectedData: [],//采购商
                link0: '', link1: '', link2: '', link3: '', link4: '', link5: '',
                options: [],
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background: true,
                    pageSize: 10
                },
                tableOpt: {
                    page: true,
                    refreshBtn: false,
                    columnBtn: false,
                    simplePage: true,
                    addBtn: false,
                    addRowBtn: false,
                    cellBtn: false,
                    index: true,
                    indexLabel: ' ',
                    border: true,
                    align: "center",
                    emptyText: this.$t('No Data'),
                    menuTitle: this.$t('OPERATION'),
                    menuWidth: 200,
                    editBtn: false,
                    delBtn: false,
                    //selection: true,
                    //reserveSelection: true,
                    tip: false,
                    column: [
                        {
                            label: this.isBrandBusiness ? this.$t('MASTER PO NUMBER') : this.$t('PO'),
                            prop: 'po',
                        },
                        {
                            label: this.isBrandBusiness ? this.$t('VENDOR') : this.$t('SUPPLIER'),
                            prop: 'vendorCorporationId',
                        },
                        {
                            label: this.$t('ORDER DATE'),
                            prop: 'orderDate',
                            type: "date",
                            format: 'yyyy-MM-dd',
                            valueFormat: 'yyyy-MM-dd',
                        },

                        {
                            label: this.isBrandBusiness ? this.$t('MASTER STYLE NUMBER') : this.$t('FINISHED ITEM NUMBER'),
                            prop: 'code',
                            width: 220
                        },
                        {
                            label: this.$t('Product Name'),
                            prop: 'productName',
                        },
                        {
                            label: this.$t('BATCH'),
                            prop: 'batch',
                        },
                        {
                            label: this.$t('COLOR'),
                            prop: 'color',
                        },

                        {
                            label: this.$t('OrderNumber'),
                            prop: 'number',
                        },
                        {
                            label: this.$t('UNIT'),
                            prop: 'unitId',
                        }
                    ]
                },
                poListTable: [],
                poListpage: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev, next",
                    background: true,
                    pageSize: 6
                },
                poListtableOpt: {
                    page: false,
                    refreshBtn: false,
                    columnBtn: false,
                    simplePage: true,
                    addBtn: false,
                    addRowBtn: false,
                    cellBtn: false,
                    //index: true,
                    //indexLabel: ' ',
                    border: true,
                    align: "center",
                    emptyText: this.$t('No Data'),
                    menuTitle: this.$t('OPERATION'),
                    menuWidth: 70,
                    editBtn: false,
                    delBtn: false,
                    //selection: true,
                    //reserveSelection: true,
                    tip: false,
                    column: [

                        //{
                        //    label: this.$t('ORDER DATE'),
                        //    prop: 'orderDate',
                        //    type: "date",
                        //    format: 'yyyy-MM-dd',
                        //    valueFormat: 'yyyy-MM-dd',
                        //    width: 100,
                        //},
                        //{
                        //    label: this.$t('VENDOR'),
                        //    prop: 'vendorCorporationId',
                        //    width: 200,
                        //},
                        {
                            label: this.$t('PO'),
                            prop: 'po',
                            width: 200,
                        },
                        {
                            label: this.$t('ProductCode'),
                            prop: 'code',
                            width: 180,
                        },

                        //{
                        //    label: this.$t('UNIT'),
                        //    prop: 'unitId',
                        //},
                        //{
                        //    label: this.$t('Number'),
                        //    prop: 'number',
                        //}
                    ]
                },
            }
        },
        created() {
            this.init()
        },
        watch: {
            $route() {
                this.init()
            }
        },
        mounted() {
            this.Coderestaurants = this.getCodeList();//数据源
        },
        methods: {
            getSelectBadge() {
                this.selectBadge = 0;
                if (this.codeVal != "") {
                    ++this.selectBadge;
                };
                if (this.poVal != "") {
                    ++this.selectBadge;
                };
                if (this.colorVal != "") {
                    ++this.selectBadge;
                };
                if (this.beginTimeVal != "") {
                    ++this.selectBadge;
                };
                if (this.endTimeVal != "") {
                    ++this.selectBadge;
                };
                if (this.vendorVal.ids.length != 0) {
                    ++this.selectBadge;
                };
                if (this.selectBadge == 0) {
                    this.selectBadge = null;
                }
            },

            init() {
                TracingUnifiedIsBool("IsBrandBusiness").then(res => {
                    this.isBrandBusiness = res.data;
                });
                TracingDropDownList('VendorList').then(res => {
                    this.vendorData = res.data;
                });
                this.poListcurrentChange();
            },
            //设置表头行的样式
            tableHeaderColor() {
                return 'background-color:#838bb3!important;padding: 0!important;'

            },//表格行央视
            rowStyle({ row, column, rowIndex }) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
            },
            moreFrom() {
                let fromHei = document.querySelector(".fromOutDiv").clientHeight;
                document.querySelector(".fromOutDiv").style.cssText = "height:auto;";
            },  //点击更多按钮
            focusDiv(obj) {//搜索框获取焦点
                if (obj.currentTarget === undefined) {
                    obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                } else {
                    if (obj.currentTarget.parentElement.getAttribute('class').indexOf('suffix') !== -1) {
                        obj.currentTarget.parentElement.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                    } else {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                    }

                }
            },//搜索框失去焦点
            blurDiv(obj) {
                if (obj.currentTarget === undefined) {
                    if (obj.value === "") {
                        obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                } else if (obj.currentTarget === null) {
                    if (this.vendorVal.ids.length === 0) {
                        document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                } else {
                    if (obj.target.value == "") {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                }
            },
            venchang() {
                if (this.vendorVal.ids.length === 0) {
                    document.querySelector("#venSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                } else {
                    document.querySelector("#venSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                }
            },//点击更多按钮
            searchFrom() {
                this.no_SeaCodeVal = "yes";
                this.getPurOrdList();
            },//查询
            getCodeList() {
                this.Codeslist = [];
                TracingStringList("ALLPurchaseOrderInfo").then(res => {
                    for (var i = 0; i < res.data.length; i++) {
                        this.Codeslist.push({
                            value: res.data[i]
                        })
                    }
                })
                return this.Codeslist;
            },
            querySearchCodeAsync(queryString, cb) {
                var Coderestaurants = this.Coderestaurants;
                var results = queryString ? Coderestaurants.filter(this.createStateFilterCode(queryString)) : Coderestaurants;
                clearTimeout(this.Codetimeout);
                this.Codetimeout = setTimeout(() => {
                    cb(results.slice(0, 10));
                }, 1000 * Math.random());
            },
            createStateFilterCode(queryString) {
                return (Codestate) => {
                    return (Codestate.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            handleChange() {
                this.getPurOrdList();
            },
            handleSelect() {
                this.getPurOrdList();
            },//选中批号
            async getPurOrdList() {
                await PoAndDetailList(this.page.currentPage, this.page.pageSize, this.vendorVal.ids.toString(), this.codeVal, this.poVal, this.colorVal, this.no_SeaCodeVal, this.SeaCodeVal, this.beginTimeVal, this.endTimeVal,).then(res => {
                    var stockListInfo = res.data.OrderStocks;
                    if (stockListInfo != null) {
                        this.potable = [];
                        for (var i = 0; i < stockListInfo.length; i++) {
                            this.potable.push({
                                id: stockListInfo[i].POInfoDetail.Id,
                                poDetailId: stockListInfo[i].POInfoDetail.Id,
                                purchaseOrderId: stockListInfo[i].POInfoDetail.PurchaseOrderId,
                                productName: stockListInfo[i].POInfoDetail.ProductName,
                                orderDate: stockListInfo[i].POInfo.OrderDate == '' ? '' : stockListInfo[i].POInfo.OrderDate.substr(0, 10),
                                po: stockListInfo[i].POInfo.Po,
                                vendorCorporationId: stockListInfo[i].POInfo.VendorCorporationId,
                                batch: stockListInfo[i].POInfoDetail.Batch,
                                color: stockListInfo[i].POInfoDetail.Color,
                                code: stockListInfo[i].POInfoDetail.Code,
                                status: stockListInfo[i].POInfoDetail.Status,
                                unitId: stockListInfo[i].POInfoDetail.UnitName,
                                number: stockListInfo[i].POInfoDetail.Number,
                            })
                        }
                    }
                    this.page.total = res.data.TotalCount;
                    this.page.pageSize = res.data.PageSize;
                    //只有一条数据
                    if (this.potable.length == 1) {
                        this.selectId = this.potable[0].poDetailId;
                        this.$router.push({ path: '/pvh_operation/orderFracing', query: { ID: this.selectId } })
                    }
                    else {
                        this.dialogTableVisible = true;
                    }

                }).catch(erro => {
                    console.log(erro)
                })
            },
            currentChange(val) {
                this.page.currentPage = val
                this.getPurOrdList();
            },

            submitSelectData(row) {
                this.selectId = row.poDetailId;
                this.$router.push({ path: '/pvh_operation/orderFracing', query: { ID: this.selectId } })
                this.dialogTableVisible = false;
            },
            SelectData() {
                this.no_SeaCodeVal = "no";
                this.getPurOrdList();
            },
            SelectLink(e) {
                this.$router.push({ path: '/pvh_operation/orderFracing', query: { ID: e } })
            },
            async getPoListPurOrdList() {
                await PoAndDetailList(this.poListpage.currentPage, this.poListpage.pageSize, "", "", "", "", "", "", "", "").then(res => {
                    var stockListInfo = res.data.OrderStocks;
                    if (stockListInfo != null) {
                        this.poListTable = [];
                        for (var i = 0; i < stockListInfo.length; i++) {
                            this.poListTable.push({
                                id: stockListInfo[i].POInfoDetail.Id,
                                poDetailId: stockListInfo[i].POInfoDetail.Id,
                                purchaseOrderId: stockListInfo[i].POInfoDetail.PurchaseOrderId,
                                productName: stockListInfo[i].POInfoDetail.ProductName,
                                orderDate: stockListInfo[i].POInfo.OrderDate == '' ? '' : stockListInfo[i].POInfo.OrderDate.substr(0, 10),
                                po: stockListInfo[i].POInfo.Po,
                                vendorCorporationId: stockListInfo[i].POInfo.VendorCorporationId,
                                batch: stockListInfo[i].POInfoDetail.Batch,
                                color: stockListInfo[i].POInfoDetail.Color,
                                code: stockListInfo[i].POInfoDetail.Code,
                                status: stockListInfo[i].POInfoDetail.Status,
                                unitId: stockListInfo[i].POInfoDetail.UnitName,
                                number: stockListInfo[i].POInfoDetail.Number,
                                businessNoIn: stockListInfo[i].POInfoDetail.BusinessNoIn,
                            })
                        }
                    }
                    this.poListpage.total = res.data.TotalCount;
                    this.poListpage.pageSize = res.data.PageSize;
                }).catch(erro => {
                    console.log(erro)
                })
            },
            poListcurrentChange(val) {
                this.poListpage.currentPage = val
                this.getPoListPurOrdList();
            },

        },
    }
</script>